export const buildChilexpressPayload = (
  shipmentInfo,
  cardNumber,
  marketplaceRut,
  originCoverageArea,
  destinationCoverageArea
) => {
  const payload = {
    header: {
      customerCardNumber: cardNumber,
      countyOfOriginCoverageCode: originCoverageArea.countyCode,
      labelType: 2,
      marketplaceRut: marketplaceRut,
    },
    details: [
      {
        addresses: [
          {
            countyCoverageCode: destinationCoverageArea.countyCode,
            streetName:
              shipmentInfo.shipping_address_1 ||
              " " ||
              shipmentInfo.shipping_address_2,
            addressType: "DEST",
          },
          {
            countyCoverageCode: originCoverageArea.countyCode,
            streetName: shipmentInfo.origin_address_city,
            addressType: "DEV",
          },
        ],
        contacts: [
          {
            name: shipmentInfo.origin_contact_name,
            phoneNumber: shipmentInfo.origin_contact_phone,
            mail: shipmentInfo.origin_contact_email,
            contactType: "R",
          },
          {
            name: shipmentInfo.shipping_name,
            phoneNumber: shipmentInfo.shipping_phone,
            mail: shipmentInfo.shipping_email,
            contactType: "D",
          },
        ],

        packages: [
          {
            weight: shipmentInfo.size_data.weight,
            height: shipmentInfo.size_data.height,
            width: shipmentInfo.size_data.width,
            length: shipmentInfo.size_data.length,
            serviceDeliveryCode: "2",
            productCode: "3",
            deliveryReference: shipmentInfo.id,
            groupReference: "GRUPO",
            declaredValue: shipmentInfo.total,
            declaredContent: 5,
            receivableAmountInDelivery:
              shipmentInfo.total,
          },
        ],
      },
    ],
    shipmentId: shipmentInfo.id,
  };

  return payload;
};

import { updateStatus } from "../api/shipment";
import { isSimilarString } from "./utils";
import { getAreas } from "../api/courier";

export const onShippingFailed = (id, courierId) => {
  const payload = {
    statusId: "8",
    transportOrder: "-",
    courierId: courierId,
  };
  console.log(payload);
  const shippingFailed = updateStatus(id, payload);
  return shippingFailed;
};

const findCoverageArea = (data, city) => {
  const area = data.find((element) =>
    isSimilarString(element.countyName.toLowerCase(), city.toLowerCase())
  );
  return area;
};

export const getCoverageArea = async (coverageAreas, city) => {
  const loadAreas = async (region_code) => {
    const response = await getAreas(1, region_code);
    return response.data.coverageAreas;
  };
  let coverageFilterArea = await loadAreas(coverageAreas);
  const coverageArea = findCoverageArea(coverageFilterArea, city);
  if (!coverageArea)
    throw new Error(
      `No se encontró cobertura para la comuna ${city} orden cambiada a estado con observación`
    );
  return coverageArea;
};
