import axios from "./axios-instance";

export const uploadFile = (file, fileName, path) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", fileName);
  formData.append("path", path);

  return axios.post("/files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
