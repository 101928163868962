<template>
  <p v-if="props.filteredShipments.length === 0">
    No se encontraron envíos pendientes
  </p>
  <DataTable
    :value="props.filteredShipments"
    showGridlines
    tableStyle="min-width: 50rem; margin-top:20px"
    :sort-field="sortField"
    :sort-order="sortOrder"
    @sort-change="onSortChange"
    v-else
  >
    <Column>
      <template #header>
        <Checkbox
          style="margin-right: 0.4rem"
          v-model="selectAll"
          @click="selectAllShipments"
          :binary="true"
        />
      </template>
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <Checkbox v-model="isSelected[data.id]" :binary="true" />
        </div>
      </template>
    </Column>
    <Column
      field="delivery_number"
      header="N° Envío"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center clickeable">
          <a @click="props.goToShipmentDetail(data)">
            <u>
              {{ data.delivery_number }}
            </u>
          </a>
        </div>
      </template>
    </Column>
    <Column
      field="sold_at"
      header="Fecha Creación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.sold_at) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="commitment_date"
      header="Fecha Compromiso"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.commitment_date) }}</span>
        </div>
      </template>
    </Column>
    <Column header="Canal de Venta" field="sale_channel"> </Column>
    <Column header="Destinatario" field="shipping_name"> </Column>
    <Column header="Dirección" field="shipping_address_1"> </Column>
    <Column header="Comuna" field="shipping_city"> </Column>
    <Column header="Valor CLP" field="total"> </Column>
    <Column header="Bodega">
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ data.origin_address_alias }}</span>
        </div>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{ data }">
        <div style="display: flex; justify-content: space-between">
          <i
            class="pi pi-pencil actions"
            @click="openEditModal(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-trash actions"
            @click="props.removeConfirm(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-comments actions"
            @click="createTicketFromShipment(data.id, data.delivery_number)"
            style="color: #326eb5"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <div v-if="props.filteredShipments.length > 0">
    Seleccionados: {{ selectedShipments.length }} envíos
  </div>
  <div style="text-align: right; padding-top: 1rem">
    <Button
      label="Submit"
      style="background-color: #eb7c2a; padding: 0.7rem"
      @click="onPrintLabelsClick"
      :disabled="isLoading || !hasSelectedShipments"
    >
      <i
        class="pi pi-print"
        style="color: white; font-size: 1rem; padding-right: 0.5rem"
      />
      Imprimir etiquetas
      <i
        v-if="isLoading"
        class="pi pi-spin pi-spinner"
        style="font-size: 1rem; margin-left: 0.5rem"
      />
    </Button>
    <Button
      style="margin-left: 1rem; padding: 0.7rem"
      label="Descargar"
      icon="pi pi-file-excel"
      @click="exportSelectedShipments"
      :disabled="isLoading || !hasSelectedShipments"
    />
  </div>
</template>
<script setup>
import { defineProps, reactive, watch, defineEmits, ref, computed } from "vue";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import { formatDateTime } from "@/services/utils";
import { handlePrintLabelsClick } from "../../services/generateLabels";
import { getCoverageArea } from "../../services/couriers";
import { createTicketFromShipment } from "../../services/support";
import { exportToExcel } from "../../services/exportExcel";

const isSelected = reactive({});
const selectAll = ref(false);
const sortField = ref(null);
const sortOrder = ref(1);
const selectedCourier = ref(null);
const isLoading = ref(false);

const emit = defineEmits(["selected-shipments"]);
const props = defineProps({
  filteredShipments: Array,
  removeConfirm: Function,
  customers: Object,
  shipments: Array,
  makeSync: Function,
  setShowEditShipment: Function,
  setCurrentShipment: Function,
  goToShipmentDetail: Function,
  showToast: Function,
});

const openEditModal = (shipment) => {
  props.setCurrentShipment(shipment);
  props.setShowEditShipment(true);
};

watch(isSelected, (newVal) => {
  const selectedIds = Object.keys(newVal).filter((id) => newVal[id]);
  const selectedShipments = selectedIds.map((id) => parseInt(id));
  emit("selected-shipments", selectedShipments);
});

watch(selectedCourier, async () => {
  getCoverageArea();
});

const onSortChange = (event) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};

watch(
  () => props.filteredShipments,
  (newValue) => {
    for (const id in isSelected) {
      isSelected[id] = false;
    }
    newValue.forEach((shipment) => {
      isSelected[String(shipment.id)] = false;
    });
  }
);

const selectAllShipments = () => {
  selectAll.value = !selectAll.value;
  for (const shipment of props.filteredShipments) {
    isSelected[String(shipment.id)] = selectAll.value;
  }
};
const selectedShipments = computed(() => {
  return Object.keys(isSelected).filter((id) => isSelected[id]);
});

const hasSelectedShipments = computed(() => {
  return selectedShipments.value.length > 0;
});

const exportSelectedShipments = () => {
  const selectedShipmentIds = selectedShipments.value;
  const selectedShipmentsData = props.shipments.filter((shipment) =>
    selectedShipmentIds.includes(String(shipment.id))
  );

  exportToExcel(selectedShipmentsData, props.customers);
};

const onPrintLabelsClick = async () => {
  try {
    isLoading.value = true;

    const selectedIds = selectedShipments.value.map((id) => String(id));
    if (selectedIds.length === 0) {
      alert("Debe seleccionar al menos un envío");
      return;
    }

    await handlePrintLabelsClick(selectedIds, props.shipments);

    isLoading.value = false;
    props.makeSync();
  } catch (error) {
    isLoading.value = false;
    console.error("Error al crear envío y generar etiquetas:", error);
  }
};
</script>
