<template>
  <div>
    <div class="card">
      <h5>Envíos</h5>
      <Toast />
      <ConfirmDialog />
      <Filters
        ref="filtersReference"
        v-if="customer.id"
        :setSerchedText="setSerchedText"
        :setStartDate="setStartDate"
        :setEndDate="setEndDate"
        :setSearchedCity="setSearchedCity"
        :customerId="customer.id"
        :setSearchedDistributionType="setSearchedDistributionType"
        :setSearchedCourierName="setSearchedCourierName"
      />
      <div id="imports">
        <SplitButton
          label="Crear envíos"
          icon="pi pi-file-excel"
          :model="items"
        />
        <Button label="Sincronizar" icon="pi pi-sync" @click="makeSync" />
      </div>
      <ProgressSpinner
        v-if="loading"
        style="width: 50px; height: 50px; margin-top: 20px; margin-left: 40%"
        class="spinner"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
      <TabView
        v-else-if="customer.id && tabCounts[0] >= 0"
        @tab-change="onTabChange"
        v-model:activeIndex="activeTab"
      >
        <TabPanel :header="`Pendientes (${tabCounts[0]})`">
          <Pendings
            :filteredShipments="filteredShipments"
            :removeConfirm="removeConfirm"
            :customers="customer"
            :shipments="shipments"
            :makeSync="makeSync"
            @selected-shipments="handleSelectedShipments"
            :setCurrentShipment="setCurrentShipment"
            :setShowEditShipment="setShowEditShipment"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`Con Observación (${tabCounts[1]})`">
          <Canceleds
            :filteredShipments="filteredShipments"
            :showToast="showToast"
            :loadShipments="loadShipments"
            :setCurrentShipment="setCurrentShipment"
            :setShowEditShipment="setShowEditShipment"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`En Preparación (${tabCounts[2]})`">
          <Preparation
            :filteredShipments="filteredShipments"
            :showToast="showToast"
            :loadShipments="loadShipments"
            :removeConfirm="removeConfirm"
            :customer="customer"
            :shipments="shipments"
            :user="user"
            :setCurrentShipment="setCurrentShipment"
            :setShowEditShipment="setShowEditShipment"
            :makeSync="makeSync"
            @selected-shipments="handleSelectedShipments"
            :goToShipmentDetail="goToShipmentDetail"
            :handlePdfPreviewUrl="handlePdfPreviewUrl"
          />
        </TabPanel>
        <TabPanel :header="`En Transportista (${tabCounts[3]})`">
          <OnCourier
            :showToast="showToast"
            :filteredShipments="filteredShipments"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`En Ruta (${tabCounts[4]})`">
          <OnRoute
            :showToast="showToast"
            :filteredShipments="filteredShipments"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`Entregados (${tabCounts[5]})`">
          <Delivered
            :showToast="showToast"
            :filteredShipments="filteredShipments"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`Fallidos (${tabCounts[6]})`">
          <Faileds
            :showToast="showToast"
            :filteredShipments="filteredShipments"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`No Entregados (${tabCounts[7]})`">
          <Returned
            :showToast="showToast"
            :filteredShipments="filteredShipments"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
        <TabPanel :header="`Todos (${shipments.length})`">
          <All
            :showToast="showToast"
            :filteredShipments="filteredShipments"
            :setActiveTab="setActiveTab"
            :setSerchedText="setSerchedText"
            :customer="customer"
            :shipments="shipments"
            :goToShipmentDetail="goToShipmentDetail"
          />
        </TabPanel>
      </TabView>
    </div>
    <Dialog
      header="Cargar Excel"
      v-model:visible="showExcelUploadModal"
      :modal="true"
    >
      <div>
        <input type="file" @change="handleExcelUpload" accept=".xls,.xlsx" />
      </div>
    </Dialog>
    <EditShipment
      :show="showEditShipment"
      :setShowEditShipment="setShowEditShipment"
      :shipmentData="currentShipment"
      :customer="customer"
      :makeSync="makeSync"
    />
    <CreateManualShipment
      :show="showCreateManualShipment"
      :setShowCreateManualShipment="setShowCreateManualShipment"
      :customer="customer"
      :makeSync="makeSync"
    />
    <ManifestPreviewModal
      :pdfPreviewUrl="pdfPreviewUrl"
      :visible="showManifestPreviewModal"
      :closeManifestPrevierModal="closeManifestPrevierModal"
    />
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import { getShipments, updateStatus } from "../../api/shipment";
import { findByUser } from "@/api/customer";
import { readUserData } from "@/services/user";
import { getSaleChannels, syncOrders } from "@/api/sale-channel";
import Pendings from "./Pendings.vue";
import Preparation from "./Preparation.vue";
import Delivered from "./Delivered.vue";
import OnCourier from "./OnCourier.vue";
import OnRoute from "./OnRoute.vue";
import Returned from "./Returned.vue";
import Faileds from "./Faileds.vue";
import Canceleds from "./Canceleds.vue";
import Filters from "./Filters.vue";
import ExcelService from "../../services/importExcel.js";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { useToast } from "primevue/usetoast";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import SplitButton from "primevue/splitbutton";
import { useRoute } from "vue-router";
import { canceledStatusId } from "@/constants";
import Dialog from "primevue/dialog";
import EditShipment from "../EditShipment.vue";
import All from "./All.vue";
import router from "@/router";
import ManifestPreviewModal from "./ManifestPreviewModal.vue";
import CreateManualShipment from "./CreateManualShipment.vue";

const shipments = ref([]);
const selectedShipments = ref([]);
const customer = ref({});
const user = readUserData();
const channels = ref([]);
const loading = ref(true);
const searchedText = ref("");
const searchedCity = ref("");
const startDate = ref("");
const endDate = ref("");
const searchedSequenceNumber = ref(1);
const toast = useToast();
const confirm = useConfirm();
const activeTab = ref(0);
const route = useRoute();
const showExcelUploadModal = ref(false);
const showEditShipment = ref(false);
const showCreateManualShipment = ref(false);
const currentShipment = ref({});
const tabCounts = ref([]);
const filtersReference = ref();
const searchedDistributionType = ref("");
const searchedCourierName = ref("");
const showManifestPreviewModal = ref(false);
const pdfPreviewUrl = ref(null);

watch(
  () => route.query,
  () => {
    checkFilterText();
  }
);

const goToShipmentDetail = (shipment) => {
  router.push("shipment-detail?id=" + shipment.id);
};

const setShowEditShipment = (value) => {
  showEditShipment.value = value;
};

const setShowCreateManualShipment = (value) => {
  showCreateManualShipment.value = value;
};

const setCurrentShipment = (shipment) => {
  currentShipment.value = shipment;
};

const setSearchedDistributionType = (value) => {
  searchedDistributionType.value = value === "Todos" ? null : value;
};

const setSearchedCourierName = (value) => {
  searchedCourierName.value = value;
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const handleSelectedShipments = (selectedIds) => {
  selectedShipments.value = selectedIds;
};

const setActiveTab = (sequenceNumber, deliveryNumber = null) => {
  setSerchedText(deliveryNumber);
  if (filtersReference.value)
    filtersReference.value.setSerchedText(deliveryNumber);
  let tab = sequenceNumber;
  if (!tab) {
    tab = route.query.tab;
  }
  if (tab) {
    activeTab.value = parseInt(tab - 1);
    searchedSequenceNumber.value = parseInt(tab);
  }
};

const checkFilterText = () => {
  const searchedText = route.query.searchedText;
  if (searchedText) {
    setSerchedText(searchedText);
    setActiveTab(9, searchedText);
  }
};

const handlePdfPreviewUrl = (pdfUrl) => {
  pdfPreviewUrl.value = pdfUrl;
  showManifestPreviewModal.value = true;
};

const closeManifestPrevierModal = () => {
  showManifestPreviewModal.value = false;
};

const getCustomer = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    console.log(err);
  }
};

const onTabChange = (event) => {
  searchedSequenceNumber.value = event.index + 1;
};

const setSerchedText = (text) => {
  searchedText.value = text;
};

const setStartDate = (date) => {
  startDate.value = date;
};

const setEndDate = (date) => {
  endDate.value = date;
};

const setSearchedCity = (city) => {
  searchedCity.value = city;
};

const items = ref([
  {
    label: "Importar excel",
    icon: "pi pi-upload",
    command: () => {
      showExcelUploadModal.value = true;
    },
  },
  {
    label: "Carga manual",
    icon: "pi pi-plus",
    command: () => {
      console.log("Carga manual");
      showCreateManualShipment.value = true;
    },
  },
]);

const handleExcelUpload = async (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    showExcelUploadModal.value = true;
    try {
      const importResult = await ExcelService.importExcelFile(selectedFile);
      if (importResult.success) {
        showToast("info", importResult.message);
        makeSync();
      } else {
        showToast("error", importResult.message);
      }
    } catch (error) {
      showToast("error", "Error en la importación: " + error.message);
    }
    showExcelUploadModal.value = false;
  }
};

const filteredShipments = computed(() => {
  return shipments.value.filter((shipment) => {
    const start = new Date(startDate.value);
    const end = new Date(endDate.value);
    const soldAt = new Date(shipment.sold_at);
    let meets = true;
    if (searchedSequenceNumber.value === 9 && !searchedText.value)
      return shipment;
    if (
      searchedSequenceNumber.value != shipment.sequence_number &&
      searchedSequenceNumber.value !== 9
    )
      return;
    if (searchedText.value) {
      meets =
        shipment.shipping_phone.toString().includes(searchedText.value) ||
        shipment.shipping_name
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        shipment.shipping_address_1
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        shipment.sale_channel
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        shipment.shipping_city
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        shipment.total
          .toString()
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        shipment.sold_at.toString().includes(searchedText.value) ||
        shipment.commitment_date.toString().includes(searchedText.value) ||
        shipment.origin_address_alias
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        shipment.sale_channel
          .toLowerCase()
          .includes(searchedText.value.toLowerCase()) ||
        (shipment.delivery_number &&
          shipment.delivery_number.toString().includes(searchedText.value));
      if (!meets) return;
    }
    if (startDate.value) {
      meets = start <= soldAt;
      if (!meets) return;
    }
    if (endDate.value) {
      meets = end >= soldAt;
      if (!meets) return;
    }
    if (searchedCity.value) {
      meets = shipment.shipping_city
        .toLowerCase()
        .includes(searchedCity.value.toLowerCase());
      if (!meets) return;
    }
    if (searchedDistributionType.value) {
      meets = shipment.distribution_type
        .toLowerCase()
        .includes(searchedDistributionType.value.toLowerCase());
      if (!meets) return;
    }
    if (searchedCourierName.value) {
      meets = shipment.courier_name
        .toLowerCase()
        .includes(searchedCourierName.value.toLowerCase());
      if (!meets) return;
    }
    return shipment;
  });
});

const getChannels = async () => {
  const saleChannels = await getSaleChannels(customer.value.id);
  channels.value = saleChannels.data;
};

const loadShipments = async () => {
  try {
    const response = await getShipments(customer.value.id);
    shipments.value = response.data;
    loading.value = false;
    countShipmentsInTab();
  } catch (err) {
    console.log(err);
  }
};

const loadData = async () => {
  try {
    await getChannels();
    const loadShipmentsPromise = loadShipments();
    const makeSyncPromise = makeSync();
    await Promise.all([loadShipmentsPromise, makeSyncPromise]);
    loading.value = false;
    setActiveTab();
    checkFilterText();
  } catch (err) {
    console.log(err);
  }
};

const makeSync = async () => {
  loading.value = true;
  const enabledSalesChannels = channels.value.filter(
    (channel) => channel.enabled
  );
  if (enabledSalesChannels.length == 0)
    showToast(
      "error",
      "Error",
      "No tiene canales de venta configurados, por favor diríjase a Configuración, Canales de Venta."
    );
  await Promise.all(
    enabledSalesChannels.map(async (channel) => {
      try {
        const resp = await syncOrders({
          customerId: customer.value.id,
          saleChannelId: channel.id,
        });
        showToast("success", "Sincronización terminada", resp.data.msg);
      } catch (err) {
        showToast(
          "error",
          "Error",
          "No se pudo sincronizar, por favor revise sus datos en Configuración, Canales de Venta."
        );
        console.log(err);
      }
    })
  );
  loadShipments();
};

const countShipmentsInTab = () => {
  for (let i = 0; i < 9; i++) {
    tabCounts.value[i] = shipments.value.filter(
      (shipment) => shipment.sequence_number == i + 1
    ).length;
  }
};

onMounted(async () => {
  await getCustomer();
  await loadData();
});

const removeConfirm = async (shipment) => {
  confirm.require({
    message: "El envío pasará a estado Con Observación, ¿desea continuar?",
    header: "Confirmación",
    icon: "pi pi-info-circle",
    acceptLabel: "Si",
    rejectLabel: "No",
    accept: () => {
      cancelShipment(shipment);
    },
    reject: () => {
      makeSync();
    },
  });
};

const cancelShipment = async (shipment) => {
  const payload = {
    statusId: canceledStatusId,
    transportOrder: shipment.transport_order,
    courierId: shipment.courier_id,
  };
  try {
    await updateStatus(shipment.id, payload);
    showToast(
      "success",
      "Orden de envío cancelada",
      "orden de envío ha sido cancelada"
    );
    loadShipments();
  } catch (err) {
    showToast("error", "Error al cancelar la orden de envío", err);
  }
};
</script>

<style scoped>
#imports {
  margin-top: 10px;
  text-align: right;
}
#imports button {
  margin-left: 5px;
}
.actions {
  color: "#326EB5";
  font-size: 14px;
  cursor: pointer;
}
</style>

<style>
.actions {
  color: "#326EB5";
  font-size: 14px;
  cursor: pointer;
}

.action-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickeable {
  cursor: pointer;
}
</style>
