<template>
  <p v-if="props.filteredShipments.length === 0">
    No se encontraron envíos cancelados
  </p>
  <DataTable
    :value="props.filteredShipments"
    showGridlines
    tableStyle="min-width: 50rem; margin-top:20px"
    :sort-field="sortField"
    :sort-order="sortOrder"
    @sort-change="onSortChange"
    v-else
  >
    <Column
      field="delivery_number"
      header="N° Envío"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center clickeable">
          <a @click="props.goToShipmentDetail(data)">
            <u>
              {{ data.delivery_number }}
            </u>
          </a>
        </div>
      </template>
    </Column>
    <Column
      field="sold_at"
      header="Fecha Creación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.sold_at) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="commitment_date"
      header="Fecha Compromiso"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.commitment_date) }}</span>
        </div>
      </template>
    </Column>
    <Column header="Canal de Venta" field="sale_channel"> </Column>
    <Column header="Destinatario" field="shipping_name"> </Column>
    <Column header="Dirección" field="shipping_address_1"> </Column>
    <Column header="Comuna" field="shipping_city"> </Column>
    <Column header="Bodega">
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ data.origin_address_alias }}</span>
        </div>
      </template>
    </Column>
    <Column header="Valor CLP" field="total"> </Column>
    <Column header="Acciones">
      <template #body="{ data }">
        <div style="display: flex; justify-content: space-between">
          <i
            class="pi pi-pencil actions"
            @click="openEditModal(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-undo actions"
            @click="backToPendings(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-comments actions"
            @click="createTicketFromShipment(data.id, data.delivery_number)"
            style="color: #326eb5"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<script setup>
import { defineProps, ref } from "vue";
import { formatDateTime } from "@/services/utils";
import { updateStatus } from "../../api/shipment";
import { createTicketFromShipment } from "../../services/support";

const sortField = ref(null);
const sortOrder = ref(1);
const props = defineProps({
  filteredShipments: Array,
  showToast: Function,
  loadShipments: Function,
  setShowEditShipment: Function,
  setCurrentShipment: Function,
  goToShipmentDetail: Function,
});

const openEditModal = (shipment) => {
  props.setCurrentShipment(shipment);
  props.setShowEditShipment(true);
};

const backToPendings = async (data) => {
  try {
    await updateStatus(data.id, {
      statusId: "1",
      label: null,
      transportOrder: null,
      courierId: data.courier_id,
    });
    props.showToast("success", "Envío devuelto a pendientes");
    props.loadShipments();
  } catch (err) {
    props.showToast("error", "No se pudo devolver a pendientes", err);
  }
};

const onSortChange = (event) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};
</script>
