<template>
  <Dialog
    v-model:visible="visible"
    :update:visible="props.setShowCreateManualShipment(visible)"
    modal
    header="Crear Envío"
    :style="{ width: '50vw' }"
  >
    <div id="form">
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <InputText
            id="destinatary_name"
            v-model="shipment.destinatary_name"
          />
          <label for="destinatary_name">Nombre del destinatario</label>
        </span>

        <span class="p-float-label field">
          <InputText
            id="destinatary_email"
            v-model="shipment.destinatary_email"
          />
          <label for="destinatary_email">Email del destinatario</label>
        </span>

        <span class="p-float-label field">
          <Dropdown
            style="width: 163px"
            id="destinatary_region"
            v-model="selectedRegion"
            :options="regions"
            optionLabel="name"
            optionValue="code"
          />
          <label for="destinatary_region" style="font-size: small"
            >Región de destino</label
          >
        </span>

        <span class="p-float-label field">
          <Dropdown
            style="width: 163px"
            id="destinatary_city"
            v-model="selectedCounty"
            :options="
              counties.map((county) => ({
                label: county.label,
                value: county.value,
              }))
            "
            optionLabel="label"
            optionValue="value"
          />
          <label for="destinatary_city" style="font-size: small"
            >Comuna destinatario</label
          >
        </span>

        <span class="p-float-label field">
          <InputText
            id="destinatary_address"
            v-model="shipment.destinatary_address"
          />
          <label for="destinatary_address">Dirección de Destino</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="destinatary_number"
            v-model="shipment.destinatary_number"
          />
          <label for="destinatary_number">Teléfono de Contacto</label>
        </span>

        <span class="p-float-label field">
          <InputText
            id="destinatary_observation"
            v-model="shipment.destinatary_observation"
          />
          <label for="destinatary_observation">Observación</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="commitment_days"
            v-model="shipment.commitment_days"
          />
          <label for="commitment_days">Días de compromiso</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="order_id"
            v-model="shipment.order_id"
          />
          <label for="order_id">ID de orden</label>
        </span>

        <span class="p-float-label field">
          <Dropdown
            style="width: 163px"
            id="store"
            v-model="shipment.store"
            :options="addresses"
            optionLabel="alias"
            optionValue="alias"
          />
          <label for="store">Bodega</label>
        </span>

        <span class="p-float-label field">
          <Dropdown
            style="width: 163px"
            id="courier_name"
            v-model="shipment.courier_name"
            :options="couriers"
            optionLabel="name"
            optionValue="name"
          />
          <label for="courier_name">Courier</label>
        </span>

        <span class="p-float-label field">
          <Dropdown
            style="width: 163px"
            id="courier_service"
            v-model="selectedCourierService"
            :options="courierServiceOptions"
          />
          <label for="courier_service">Servicio del courier</label>
        </span>

        <span class="p-float-label field">
          <Dropdown
            style="width: 163px"
            id="courier_distribution_type"
            v-model="selectedDistributionType"
            :options="distributionTypeOptions"
          />
          <label for="courier_distribution_type">Tipo de distribución</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="height"
            v-model="shipment.product_sizes.height"
          />
          <label for="height">Alto del producto</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="width"
            v-model="shipment.product_sizes.width"
          />
          <label for="width">Ancho del producto</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="length"
            v-model="shipment.product_sizes.length"
          />
          <label for="length">Largo del producto</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="weight"
            v-model="shipment.product_sizes.weight"
          />
          <label for="weight">Peso del producto</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="number_of_packages"
            v-model="shipment.number_of_packages"
          />
          <label for="number_of_packages">Número de paquetes</label>
        </span>

        <span class="p-float-label field">
          <InputNumber
            inputId="withoutgrouping"
            :useGrouping="false"
            id="product_value"
            v-model="shipment.product_value"
          />
          <label for="product_value">Valor del producto</label>
        </span>

        <span class="p-float-label field">
          <InputText
            id="description"
            v-model="shipment.description"
            style="width: 340px"
          />
          <label for="description">Descripción del producto</label>
        </span>
      </div>

      <div class="formgroup-inline form-line">
        <Button label="Guardar" icon="pi pi-check" @click="save" />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import { ref, watch, defineProps, reactive } from "vue";
import { createShipments } from "@/api/shipment";
import { getAddresses } from "@/api/address.js";
import { getRegions } from "@/api/region";
import { getAreas, getCouriers } from "@/api/courier.js";
import { useToast } from "primevue/usetoast";
import { readUserData } from "@/services/user.js";
import { findByUser } from "@/api/customer.js";

const props = defineProps({
  setShowCreateManualShipment: Function,
  show: Boolean,
  makeSync: Function,
});
const visible = ref(false);
const shipment = reactive({
  sale_channel_id: 2,
  commitment_days: null,
  order_id: null,
  store: "",
  destinatary_name: "",
  destinatary_number: null,
  destinatary_email: "",
  destinatary_address: "",
  destinatary_observation: "",
  courier_name: "",
  product_sizes: {
    height: null,
    width: null,
    length: null,
    weight: null,
  },
  number_of_packages: null,
  product_value: null,
  description: "",
});
const selectedRegionName = ref("");
const selectedCounty = ref("");
const selectedCourierService = ref("");
const selectedDistributionType = ref("");
const regions = ref([]);
const selectedRegion = ref("");
const customer = ref({});
const counties = ref([]);
const addresses = ref([]);
const couriers = ref([]);
const toast = useToast();
const user = readUserData();

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const courierServiceOptions = ["Envío Prioritario"];
const distributionTypeOptions = ["Domicilio", "Sucursal"];

const resetFields = () => {
  shipment.destinatary_name = "";
  shipment.destinatary_number = null;
  shipment.destinatary_email = "";
  shipment.destinatary_address = "";
  shipment.destinatary_observation = "";
  shipment.courier_name = "";
  shipment.product_sizes.height = null;
  shipment.product_sizes.width = null;
  shipment.product_sizes.length = null;
  shipment.product_sizes.weight = null;
  shipment.number_of_packages = null;
  shipment.product_value = null;
  shipment.description = "";
  shipment.commitment_days = null;
  shipment.order_id = null;
  shipment.store = "";
  selectedRegion.value = "";
  selectedCounty.value = "";
  selectedCourierService.value = "";
  selectedDistributionType.value = "";
  selectedRegionName.value = "";
};

const save = async () => {
  if (!Object.values(shipment).every((field) => field)) {
    showToast("warn", "Advertencia", "Debe rellenar todos los campos.");
    return;
  }
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(shipment.destinatary_email)) {
    showToast(
      "warn",
      "Advertencia",
      "Por favor ingrese un correo electrónico válido."
    );
    return;
  }

  if (
    shipment.destinatary_number.toString().length < 9 ||
    shipment.destinatary_number.toString().length > 12
  ) {
    showToast(
      "warn",
      "Advertencia",
      "El Teléfono debe contener entre 9 y 12 dígitos de largo"
    );
    return;
  }

  try {
    const shipmentData = {
      ...shipment,
      destinatary_region: selectedRegionName.value,
      destinatary_city: selectedCounty.value,
      courier_service: selectedCourierService.value,
      customer: customer.value.name,
      courier_distribution_type: selectedDistributionType.value,
    };
    await createShipments(shipmentData);

    showToast("success", "Éxito", "Envío creado con éxito.");

    resetFields();
    visible.value = false;
    props.makeSync();
  } catch (error) {
    showToast("error", "Error", "Ha ocurrido un error al crear el envío");

    console.error("Error al crear el envío:", error);
  }
};

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const loadCouriers = async () => {
  try {
    const resp = await getCouriers(customer.value.id);
    couriers.value = resp.data;
  } catch (error) {
    console.error("Error al cargar los couriers:", error);
  }
};

const loadAddresses = async () => {
  const resp = await getAddresses(customer.value.id);
  addresses.value = resp.data;
};

const loadRegions = async () => {
  try {
    const { data } = await getRegions();
    regions.value = data;
  } catch (error) {
    console.error("Error al cargar las regiones:", error);
  }
};

const loadCounties = async () => {
  try {
    if (selectedRegion.value) {
      const courierId = 1;
      const regionCode = selectedRegion.value;

      const response = await getAreas(courierId, regionCode);

      const areas = response.data.coverageAreas || [];

      const uniqueCounties = Array.from(
        new Set(areas.map((area) => area.countyName))
      );

      counties.value = uniqueCounties.map((county) => ({
        label: county,
        value: county,
      }));
    } else {
      counties.value = [];
    }
  } catch (error) {
    console.error("Error al cargar las comunas:", error);
  }
};

const startComponent = async () => {
  await getCustomerData();
  if (!customer.value.id) {
    return;
  }
  await loadCouriers();
  await loadRegions();
  await loadAddresses();
};

watch(
  () => props.show,
  (newValue) => {
    visible.value = newValue;
    startComponent();
  }
);

watch(
  () => selectedRegion.value,
  (newValue) => {
    const region = regions.value.find((region) => region.code === newValue);
    selectedRegionName.value = region ? region.name : "";
    loadCounties();
  }
);
</script>

<style scoped>
#form {
  padding: 20px;
}
.field {
  margin-top: 10px;
}
</style>
