import axios from "./axios-instance";

export const setConfig = (payload) => {
  return axios.put(`/sale-channels/config`, payload);
};

export const getAttributes = (saleChannelId) => {
  return axios.get(`/sale-channels/attributes/${saleChannelId}`);
};

export const getSaleChannels = (customerId) => {
  return axios.get(`/sale-channels/${customerId}`);
};

export const syncOrders = (payload) => {
  return axios.post(`/sale-channels/sync`, payload);
};
