import axios from "./axios-instance";

export const createManifest = (payload) => {
  return axios.post("/manifests", payload);
};

export const deleteManifest = (manifestId) => {
  return axios.delete(`/manifests/${manifestId}`);
};

export const getManifests = (customerId) => {
  return axios.get(`/manifests/customer/${customerId}`);
};

export const saveFileName = (manifestId, fileName, isConfirmation = false) => {
  return axios.put(`/manifests/${manifestId}`, { fileName, isConfirmation });
};

export const getManifestById = (manifestId) => {
  return axios.get(`/manifests/${manifestId}`);
};
