import * as XLSX from "xlsx";
import { createShipments } from "../api/shipment";

export function importExcelFile(selectedFile) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = (event) => {
      try {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = "Anexo 1";

        if (workbook.SheetNames.includes(sheetName)) {
          const worksheet = workbook.Sheets[sheetName];
          const range = "A5:V15";
          const rows = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            range: range,
          });

          const totalRows = rows.length;
          let importedRows = 0;

          const importPromises = rows.map(async (rowData) => {
            const payload = {
              sale_channel_id: 2,
              commitment_days: rowData[0],
              order_id: rowData[1],
              customer: rowData[2],
              store: rowData[3],
              destinatary_name: rowData[4],
              destinatary_number: rowData[5],
              destinatary_email: rowData[6],
              destinatary_region: rowData[7],
              destinatary_city: rowData[8],
              destinatary_address: rowData[9],
              destinatary_observation: rowData[10],
              courier_name: rowData[11],
              courier_service: rowData[12],
              courier_distribution_type: rowData[13],
              product_sizes: {
                height: rowData[14],
                width: rowData[15],
                length: rowData[16],
                weight: rowData[17],
              },
              number_of_packages: rowData[18],
              product_value: rowData[19],
              description: rowData[20],
            };

            return createShipments(payload)
              .then(() => {
                importedRows++;
              })
              .catch((error) => {
                console.log(error);
              });
          });
          Promise.all(importPromises)
            .then(() => {
              resolve({
                success: true,
                message: `Se importaron ${importedRows} de ${totalRows} filas.`,
              });
            })
            .catch(() => {
              reject({
                success: false,
                message: `Se importaron ${importedRows} de ${totalRows} filas.`,
              });
            });
        } else {
          reject({ success: false, message: "Hoja no encontrada" });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fileReader.readAsBinaryString(selectedFile);
  });
}

export default { importExcelFile };