import router from "@/router";

import { defaultSupportMessage } from "../constants";
import { useTicketStore } from "@/stores/ticket";

const ticketStore = useTicketStore();

export const openWhatsAppSupport = (id) => {
  window.open(
    `https://wa.me/${
      process.env.VUE_APP_SUPPORT_PHONE
    }?text=${defaultSupportMessage(id)}`,
    "_blank"
  );
};

export const createTicketFromShipment = (id, deliveryNumber) => {
  ticketStore.setShipmentId(id, deliveryNumber);
  router.push({ path: "/support" });
};

export const createTicketFromManifest = (manifestNumber) => {
  ticketStore.setManifestNumber(manifestNumber);
  router.push({ path: "/support" });
};
