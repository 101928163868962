import { defineStore } from "pinia";

export const useTicketStore = defineStore("ticket", {
  state: () => ({
    shipmentId: null,
    deliveryNumber: null,
    manifestNumber: null,
  }),
  getters: {
    hasShipmentId: (state) => !!state.shipmentId,
    hasManifestNumber: (state) => !!state.manifestNumber,
  },
  actions: {
    setShipmentId(shipmentId, deliveryNumber = null) {
      if (shipmentId === null) {
        this.shipmentId = null;
        this.deliveryNumber = null;
        return;
      }
      this.shipmentId = shipmentId;
      this.deliveryNumber = deliveryNumber;
    },
    setManifestNumber(manifestNumber) {
      this.manifestNumber = manifestNumber;
    },
  },
});
