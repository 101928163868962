import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import JsBarcode from "jsbarcode";
import { formatDate } from "./utils";
import { getManifestById } from "@/api/manifest";

const generateManifestPDF = (pdf, shipments, id) => {
  const formattedDate = formatDate(new Date());

  pdf.setFontSize(18);
  pdf.setTextColor(0, 0, 0);
  pdf.text(
    "MANIFIESTO RESUMEN",
    pdf.internal.pageSize.getWidth() / 2, //AQUÍ VA EL TÍTULO DEL PDF
    20,
    "center"
  );

  const margin = 10;
  const tableWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
  pdf.rect(margin, 30, tableWidth, 70); //AQUÍ VA EL RECUADRO QUE CONTIENE LA INFO PRINCIPAL DEL MANIFIESTO

  pdf.setFontSize(16);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("helvetica", "bold");
  pdf.text(shipments[0].courier_name.toUpperCase(), margin + 10, 70); //NOMBRE DEL COURIER DENTRO DEL RECUADRO

  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("helvetica", "normal");
  pdf.text("NÚMERO MANIFIESTO: " + id, margin + 165, 45); //NÚMERO DE MANIFIESTO DENTRO DEL RECUADRO

  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("helvetica", "normal");
  pdf.text(
    "CLIENTE:                         " +
      shipments[0].customer_name.toUpperCase(), //NOMBRE DEL CLIENTE QUE VA DENTRO DEL RECUADRO
    margin + 165,
    60
  );

  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("helvetica", "normal");
  pdf.text("FECHA DE CIERRE MANIFIESTO: " + formattedDate, margin + 380, 45); //FECHA DE CIERRE DEL MANIFIESTO DENTRO DEL RECUADRO

  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("helvetica", "normal");
  pdf.text(
    "TOTAL ENVÍOS:                               " + shipments.length, //CANTIDAD DE ENVÍOS DENTRO DEL RECUADRO
    margin + 380,
    60
  );

  const totalBultos = shipments.reduce(
    (acc, shipment) => acc + parseInt(shipment.packages, 10), //CANTIDAD TOTAL DE BULTOS DENTRO DEL RECUADRO
    0
  );
  const formattedTotalBultos = Number(totalBultos).toString();

  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("helvetica", "normal");
  pdf.text(
    "TOTAL BULTOS:                              " + formattedTotalBultos, //CANTIDAD TOTAL DE BULTOS DENTRO DEL RECUADRO
    margin + 380,
    75
  );

  let yPosition = 120;
  const headers = [
    //AQUÍ VAN LOS HEADERS DE LA TABLA
    "NRO",
    "DESTINATARIO",
    "COMUNA",
    "REFERENCIA",
    "SEGUIMIENTO",
    "BULTOS",
  ];

  const data = shipments.map((shipment, index) => {
    return [
      index + 1,
      shipment.shipping_name,
      shipment.shipping_city,
      shipment.delivery_number, //AQUÍ VA LA INFO DENTRO DE CADA COLUMNA DE LA TABLA
      "",
      shipment.packages,
    ];
  });

  const imgDataArray = shipments.map((shipment) => {
    const barcodeData = shipment.barcode;
    const barcodeCanvas = document.createElement("canvas");
    JsBarcode(barcodeCanvas, barcodeData, {
      format: "CODE128",
      width: 2,
      height: 30,
    });

    return barcodeCanvas.toDataURL("image/png", 1.0); //AQUÍ SE CREA LA URL DE LA IMAGEN DEL CÓDIGO DE BARRA
  });

  const headStyles = {
    fillColor: [211, 211, 211],
    textColor: [0, 0, 0],
    fontSize: 10,
  };

  let initialPosition = yPosition;

  const table = {
    //AQUÍ SE CREA LA TABLA
    startY: yPosition,
    head: [headers],
    body: data,
    styles: {
      fontSize: 10,
      valign: "middle",
      halign: "center",
    },
    bodyStyles: { minCellHeight: 40 },
    didDrawCell: function (data) {
      if (data.column.index === 4 && data.cell.section === "body") {
        const imgData = imgDataArray[data.row.index];

        pdf.addImage(imgData, data.cell.x + 10, data.cell.y + 5, 100, 30); //ESTA ES LA LÓGICA PARA AGREGAR LAS IMAGENES EN LA COLUMNA CORRESPONDIENTE
      }
    },
    theme: "grid",
    margin: margin,
    headStyles: headStyles,
  };

  autoTable(pdf, table); //SE AGREGA LA TABLA AL DOCUMENTO

  yPosition = parseInt(initialPosition);

  const heightTable = parseInt(pdf.lastAutoTable.finalY - yPosition);

  yPosition += heightTable + 20; //SE CALCULA LA ALTURA DE LA TABLA Y SE LE SUMA 20 PARA PONER EL RECUADRO QUE VIENE DESPUES

  pdf.rect(margin, yPosition, tableWidth, 70);

  const partWidth = tableWidth / 3;
  pdf.setLineWidth(0.5);
  pdf.setDrawColor(0, 0, 0);

  pdf.line(margin + partWidth, yPosition, margin + partWidth, yPosition + 70); // AQUÍ SE GENERA EL RECUADRO DIVIDIDO EN TRES PARA PONER FIRMAS Y OTROS DATOS
  pdf.line(
    margin + 2 * partWidth,
    yPosition,
    margin + 2 * partWidth,
    yPosition + 70
  );

  const espacio1Y = yPosition + 10;
  pdf.line(margin, espacio1Y, margin + tableWidth, espacio1Y);

  pdf.setFontSize(9);
  const clienteTextWidth = pdf.getStringUnitWidth("CLIENTE") * 11;
  const clienteTextX = margin + partWidth / 2 - clienteTextWidth / 2; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  pdf.text("CLIENTE", clienteTextX, espacio1Y - 2);

  pdf.setFontSize(9);
  const transporteTextWidth = pdf.getStringUnitWidth("TRANSPORTE") * 10; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  const transporteTextX =
    margin + partWidth + partWidth / 2 - transporteTextWidth / 2;
  pdf.text("TRANSPORTE", transporteTextX, espacio1Y - 2);

  pdf.setFontSize(9);
  const admisionTextWidth = pdf.getStringUnitWidth("ADMISIÓN") * 10; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  const admisionTextX =
    margin + 2 * partWidth + partWidth / 2 - admisionTextWidth / 2;
  pdf.text("ADMISIÓN", admisionTextX, espacio1Y - 2);

  const textoFirma = "NOMBRE - RUT - FIRMA";
  pdf.setFontSize(6);
  const textoFirmaWidth = pdf.getStringUnitWidth(textoFirma) * 6; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  const textoFirmaX = margin + partWidth / 2 - textoFirmaWidth / 2;
  pdf.text(textoFirma, textoFirmaX, espacio1Y + 55);

  const espacio2Y = espacio1Y;
  pdf.setFontSize(8);
  const fechaHoraRetiroWidth =
    pdf.getStringUnitWidth("FECHA Y HORA RETIRO") * 16.5; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  const fechaHoraRetiroX =
    margin + partWidth + partWidth / 2 - fechaHoraRetiroWidth / 2;
  pdf.text("FECHA Y HORA RETIRO", fechaHoraRetiroX, espacio2Y + 12);

  pdf.setFontSize(8);
  const nameRutWidth = pdf.getStringUnitWidth("NOMBRE Y RUT") * 24.9;
  const nameRutX = margin + partWidth + partWidth / 2 - nameRutWidth / 2; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  pdf.text("NOMBRE Y RUT", nameRutX, espacio2Y + 24);

  pdf.setFontSize(8);
  const palletWidth =
    pdf.getStringUnitWidth("CANTIDAD PALLET RETIRADOS") * 12.5;
  const palletX = margin + partWidth + partWidth / 2 - palletWidth / 2; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  pdf.text("CANTIDAD PALLET RETIRADOS", palletX, espacio2Y + 36);

  pdf.setFontSize(8);
  const signatureWidth = pdf.getStringUnitWidth("FIRMA") * 60.5;
  const signatureX = margin + partWidth + partWidth / 2 - signatureWidth / 2; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  pdf.text("FIRMA", signatureX, espacio2Y + 48);

  const espacio3Y = yPosition + 10;
  pdf.line(margin + partWidth * 2, espacio3Y, margin + tableWidth, espacio3Y);
  pdf.setFontSize(6);
  const nombreRutFirmaWidth =
    pdf.getStringUnitWidth("NOMBRE - RUT - FIRMA") * 6; //AQUÍ SE PONEN LOS DATOS DE LOS RECUADROS
  const nombreRutFirmaX =
    margin + 2 * partWidth + partWidth / 2 - nombreRutFirmaWidth / 2;
  pdf.text("NOMBRE - RUT - FIRMA", nombreRutFirmaX, espacio3Y + 55);

  const totalPages = pdf.internal.getNumberOfPages();

  // Volver al principio del documento para agregar el número total de páginas en la primera página
  pdf.setPage(1);

  // Agregar el número total de páginas en las coordenadas específicas de la primera página
  pdf.setFontSize(10);
  pdf.text(
    "PÁGINAS:                                         " + totalPages, //CANTIDAD DE PÁGINAS DENTRO DEL RECUADRO
    margin + 380,
    90
  );
  return pdf;
};

export const getManifestPDF = (shipments, id) => {
  const pdf = new jsPDF("p", "pt", "letter");
  generateManifestPDF(pdf, shipments, id); // AQUÍ SE GENERA EL PDF
  return pdf;
};

export const downloadManifest = async (shipment) => {
  const baseUrl = process.env.VUE_APP_FILES_URL;
  try {
    const manifest = await getManifestById(shipment.manifest_id);
    window.open(`${baseUrl}/${manifest.data.data.file_name}`, "_blank");
  } catch (err) {
    console.error(err);
  }
};
