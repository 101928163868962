<template>
  <div id="filters">
    <div class="formgroup-inline">
      <span class="p-float-label field p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          class="p-inputtext-lg md:w-30rem"
          @keyup="props.setSerchedText(searchedText)"
          v-model="searchedText"
        />
        <label for="company-rut">Buscar</label>
      </span>

      <span class="p-float-label field">
        <Calendar
          v-model="startDate"
          @date-select="props.setStartDate(startDate)"
          @input="props.setStartDate(startDate)"
          dateFormat="dd/mm/yy"
          class="p-inputtext-lg md:w-17rem"
        />
        <label for="company-rut">Inicio</label>
      </span>

      <span class="p-float-label field">
        <Calendar
          v-model="endDate"
          @date-select="props.setEndDate(endDate)"
          @input="props.setEndDate(endDate)"
          dateFormat="dd/mm/yy"
          class="p-inputtext-lg md:w-17rem"
        />
        <label for="company-web">Término</label>
      </span>
    </div>

    <div class="formgroup-inline">
      <span class="p-float-label field">
        <Dropdown
          v-model="selectedDeliverType"
          :options="deliverTypes"
          optionLabel="name"
          class="p-inputtext-lg w-full md:w-20rem"
          @change="handleDeliverTypeChange"
        />
        <label for="company-web">Tipo de entrega</label>
      </span>
      <span class="p-float-label field">
        <Dropdown
          v-model="selectedCourier"
          :options="couriers"
          optionLabel="name"
          class="p-inputtext-lg w-full md:w-20rem"
          @change="
            () => {
              if (selectedCourier.id === 0) {
                props.setSearchedCourierName('');
                return;
              }
              props.setSearchedCourierName(selectedCourier.name);
            }
          "
        />
        <label for="company-web">Courier</label>
      </span>

      <span class="p-float-label field">
        <InputText
          id="company-rut"
          class="p-inputtext-lg md:w-24rem"
          v-model="searchedCity"
          @keyup="props.setSearchedCity(searchedCity)"
        />
        <label for="company-rut">Comuna</label>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineExpose } from "vue";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import { getCouriers } from "@/api/courier";

const endDate = ref("");
const searchedCity = ref("");
const startDate = ref("");
const searchedText = ref("");
const selectedDeliverType = ref();
const deliverTypes = ref([
  { name: "Todos", code: "ALL" },
  { name: "Domicilio", code: "DOM" },
  { name: "Sucursal", code: "SUC" },
]);
const selectedCourier = ref();
const couriers = ref([]);

const handleDeliverTypeChange = () => {
  if (selectedDeliverType.value.code === "ALL") {
    props.setSearchedDistributionType(null);
  } else {
    props.setSearchedDistributionType(selectedDeliverType.value.name);
  }
};

const props = defineProps({
  setSerchedText: Function,
  setStartDate: Function,
  setEndDate: Function,
  setSearchedCity: Function,
  customerId: String,
  setSearchedDistributionType: Function,
  setSearchedCourierName: Function,
});

const setSerchedText = (val) => {
  searchedText.value = val;
};

defineExpose({
  setSerchedText,
});

const loadData = async () => {
  try {
    const response = await getCouriers(props.customerId);
    couriers.value = response.data;
    couriers.value.push({ name: "Todos", id: 0 });
  } catch (err) {
    console.log(err);
  }
};
loadData();
</script>
<style scoped lang="scss">
#filters {
  border-style: solid;
  border-color: #d1d1d1;
  padding: 20px;
  border-radius: 5px;
}
.check {
  margin-right: 20px;
}
</style>
