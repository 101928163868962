<template>
  <p v-if="props.filteredShipments.length === 0">
    No se encontraron envíos devueltos
  </p>
  <DataTable
    :value="props.filteredShipments"
    showGridlines
    tableStyle="min-width: 50rem; margin-top:20px"
    :sort-field="sortField"
    :sort-order="sortOrder"
    @sort-change="onSortChange"
    v-else
  >
    <Column>
      <template #header>
        <Checkbox
          style="margin-right: 0.4rem"
          v-model="selectAll"
          @click="selectAllShipments"
          :binary="true"
        />
      </template>
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <Checkbox v-model="isSelected[data.id]" :binary="true" />
        </div>
      </template>
    </Column>
    <Column
      field="delivery_number"
      header="N° Envío"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center clickeable">
          <a @click="props.goToShipmentDetail(data)">
            <u>
              {{ data.delivery_number }}
            </u>
          </a>
        </div>
      </template>
    </Column>
    <Column header="Orden de transporte" field="transport_order"> </Column>
    <Column
      field="sold_at"
      header="Fecha Creación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.sold_at) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="commitment_date"
      header="Fecha Compromiso"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.commitment_date) }}</span>
        </div>
      </template>
    </Column>
    <Column header="Canal de Venta" field="sale_channel"> </Column>
    <Column header="Destinatario" field="shipping_name"> </Column>
    <Column header="Dirección" field="shipping_address_1"> </Column>
    <Column header="Comuna" field="shipping_city"> </Column>
    <Column header="Valor CLP" field="total"> </Column>
    <Column header="Bodega">
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ data.origin_address_alias }}</span>
        </div>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{ data }">
        <div class="action-list">
          <i
            class="pi pi-comments actions"
            @click="createTicketFromShipment(data.id, data.delivery_number)"
            style="color: #326eb5"
          />
          <i
            v-if="data.manifest_id"
            class="pi pi-file actions"
            @click="downloadManifest(data)"
            style="color: #326eb5; cursor: pointer; margin-left: 5px"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <div v-if="props.filteredShipments.length > 0">
    Seleccionados: {{ selectedShipments.length }} envíos
  </div>
  <div style="text-align: right; padding-top: 1rem">
    <Button
      label="Submit"
      style="background-color: #eb7c2a; padding: 0.7rem"
      @click="onPrintLabelsClick"
      :disabled="isLoading || !hasSelectedShipments"
    >
      <i
        class="pi pi-print"
        style="color: white; font-size: 1rem; padding-right: 0.5rem"
      />
      Imprimir etiquetas
      <i
        v-if="isLoading"
        class="pi pi-spin pi-spinner"
        style="font-size: 1rem; margin-left: 0.5rem"
      />
    </Button>
    <Button
      style="margin-left: 1rem; padding: 0.7rem"
      label="Descargar"
      icon="pi pi-file-excel"
      @click="exportSelectedShipments"
      :disabled="isLoading || !hasSelectedShipments"
    />
  </div>
</template>
<script setup>
import { defineProps, ref, reactive, computed, watch } from "vue";
import { formatDateTime } from "@/services/utils";
import { createTicketFromShipment } from "../../services/support";
import Checkbox from "primevue/checkbox";
import { printLabels } from "../../services/shipments";
import { exportToExcel } from "../../services/exportExcel";
import { downloadManifest } from "../../services/manifest";

const isLoading = ref(false);
const sortField = ref(null);
const sortOrder = ref(1);
const isSelected = reactive({});
const selectAll = ref(false);
const props = defineProps({
  filteredShipments: Array,
  customer: Object,
  shipments: Array,
  goToShipmentDetail: Function,
  showToast: Function,
});

watch(
  () => props.filteredShipments,
  (newValue) => {
    for (const id in isSelected) {
      isSelected[id] = false;
    }
    newValue.forEach((shipment) => {
      isSelected[String(shipment.id)] = false;
    });
  }
);

const onSortChange = (event) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};

const selectedShipments = computed(() => {
  return Object.keys(isSelected).filter((id) => isSelected[id]);
});

const hasSelectedShipments = computed(() => {
  return selectedShipments.value.length > 0;
});

const getSelectedShipments = (selectedIds) => {
  return props.filteredShipments.filter((shipment) =>
    selectedIds.includes(String(shipment.id))
  );
};

const getShipmentLabels = () => {
  const selectedIds = Object.keys(isSelected).filter((id) => isSelected[id]);
  const selectedShipments = getSelectedShipments(selectedIds);
  return selectedShipments.map((shipment) => {
    return shipment.label;
  });
};

const selectAllShipments = () => {
  selectAll.value = !selectAll.value;
  for (const shipment of props.filteredShipments) {
    isSelected[String(shipment.id)] = selectAll.value;
  }
};

const exportSelectedShipments = () => {
  const selectedShipmentIds = selectedShipments.value;
  const selectedShipmentsData = props.shipments.filter((shipment) =>
    selectedShipmentIds.includes(String(shipment.id))
  );

  exportToExcel(selectedShipmentsData, props.customer);
};

const onPrintLabelsClick = async () => {
  try {
    isLoading.value = true;
    const labels = getShipmentLabels();
    printLabels(labels);

    isLoading.value = false;
  } catch (err) {
    props.showToast("error", "Error al imprimir las etiquetas", err);
    isLoading.value = false;
  }
};
</script>
