import * as XLSX from "xlsx";
import saveAs from "file-saver";
import { formatDateTime, transitDays } from "../services/utils";

export const createExcel = (data) => {
  try {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Envios");
    const fileName = "Envíos.xlsx";

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, fileName);
  } catch (error) {
    console.error("Error al exportar a Excel:", error);
  }
};

export const exportToExcel = (filteredShipments, customer) => {
  const data = filteredShipments.map((shipment) => ({
    Estado: shipment.status,
    "N° Envío": shipment.delivery_number,
    OT: shipment.transport_order,
    "Fecha Creación": formatDateTime(shipment.sold_at),
    "Fecha Integración": formatDateTime(shipment.created_at),
    "Fecha Compromiso": formatDateTime(shipment.commitment_date),
    "Días en Tránsito": transitDays(shipment),
    "ID venta": shipment.order_id,
    Empresa: customer.name,
    Bodega: shipment.origin_address_alias,
    "Comuna bodega": shipment.origin_address_city,
    "Dirección bodega": shipment.origin_address,
    Nombre: shipment.shipping_name,
    Teléfono: shipment.shipping_phone,
    Email: shipment.shipping_email,
    Comuna: shipment.shipping_city,
    Dirección: shipment.shipping_address_1,
    Observación: shipment.shipping_observation,
    Courier: shipment.courier_name,
    Servicio: shipment.service,
    "Tipo de distribución": shipment.distribution_type,
    Alto: shipment.size_data.height,
    Ancho: shipment.size_data.width,
    Largo: shipment.size_data.length,
    Peso: shipment.size_data.weight,
    "N° de Bultos": shipment.packages,
    "Valor producto": shipment.total,
    Descripcion: shipment.description,
  }));
  createExcel(data);
};
