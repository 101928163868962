import axios from "./axios-instance";

export const getAddresses = (customerId) => {
  return axios.get(`/addresses/${customerId}`);
};

export const saveAddress = (payload) => {
  return axios.post("/addresses", payload);
};

export const deleteAddress = (addressId) => {
  return axios.delete(`/addresses/${addressId}`);
};
