<template>
  <Dialog
    v-model:visible="visible"
    :update:visible="props.setShowEditShipment(visible)"
    modal
    header="Datos del Envío"
    :style="{ width: '50vw' }"
    v-on:hide="props.makeSync()"
  >
    <div id="form">
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.order_id"
          />
          <label for="name">Id Orden</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.buyer_name"
          />
          <label for="name">Nombre del comprador</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.buyer_email"
          />
          <label for="name">Email del comprador</label>
        </span>
      </div>
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.shipping_name"
          />
          <label for="name">Nombre destinatario</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.shipping_address_1"
          />
          <label for="name">Dirección de destino</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.shipping_city"
          />
          <label for="name">Ciudad de destino</label>
        </span>
      </div>
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <Dropdown
            v-model="selectedRegion"
            placeholder="Seleccione una región"
            :options="regions"
            optionLabel="name"
            style="width: 200px"
          />
          <label for="name">Región de destino</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.shipping_postcode"
          />
          <label for="name">Código postal</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.shipping_phone"
          />
          <label for="name">Teléfono envío</label>
        </span>
      </div>
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.products"
          />
          <label for="name">Productos</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.packages"
          />
          <label for="name">Bultos</label>
        </span>
        <span class="p-float-label field">
          <Dropdown
            v-model="selectedAddress"
            placeholder="Seleccione una dirección"
            :options="addresses"
            optionLabel="alias"
            style="width: 200px"
          />
          <label for="name">Dirección de Origen</label>
        </span>
      </div>
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.size_data.length"
          />
          <label for="name">Largo</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.size_data.height"
          />
          <label for="name">Alto</label>
        </span>
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.size_data.width"
          />
          <label for="name">Ancho</label>
        </span>
      </div>
      <div class="formgroup-inline form-line">
        <span class="p-float-label field">
          <InputText
            class="p-inputtext-lg input"
            v-model="shipment.value.size_data.weight"
          />
          <label for="name">Peso</label>
        </span>
      </div>
      <Button label="Guardar" icon="pi pi-check" @click="save" />
    </div>
  </Dialog>
</template>
<script setup>
import Dialog from "primevue/dialog";
import { defineProps, watch, ref } from "vue";
import { getRegions } from "@/api/region";
import { getAddresses } from "@/api/address";
import { updateShipment } from "@/api/shipment";

const props = defineProps({
  show: Boolean,
  setShowEditShipment: Function,
  shipmentData: Object,
  customer: Object,
  makeSync: Function,
});
const visible = ref(false);
const shipment = {};
const regions = ref([]);
const selectedRegion = ref({});
const addresses = ref([]);
const selectedAddress = ref({});

const findRegion = (regionCode) => {
  return regions.value.find((region) => region.code === regionCode);
};

const loadRegions = async () => {
  try {
    if (!shipment.value) return;
    const { data } = await getRegions();
    regions.value = data;
    selectedRegion.value = findRegion(shipment.value.region_code);
  } catch (err) {
    console.log(err);
  }
};

loadRegions();

const loadAddresses = async () => {
  const resp = await getAddresses(props.customer.id);
  addresses.value = resp.data;
  selectedAddress.value = addresses.value.find(
    (address) => address.id === shipment.value.origin_adress_id
  );
};

watch(
  () => props.show,
  (newValue) => {
    visible.value = newValue;
    loadRegions();
    loadAddresses();
  }
);
watch(
  () => props.shipmentData,
  (newValue) => {
    shipment.value = newValue;
  }
);

const save = () => {
  shipment.value.region_iso_code = selectedRegion.value.iso_code;
  shipment.value.origin_adress_id = selectedAddress.value.id;
  shipment.value.transport_order = "-";
  updateShipment(shipment.value);
  props.setShowEditShipment(false);
};
</script>

<style scoped>
#form {
  padding: 20px;
}
.field {
  margin-top: 10px;
}
</style>
