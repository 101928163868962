export const printLabels = async (labels) => {
  if (!Array.isArray(labels) || labels.length === 0 || labels[0] === null) {
    alert("No se han proporcionado etiquetas para imprimir.");
    return;
  }
  window.open(
    "/print",
    "Impresión de etiquetas",
    "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=800,top=0,left=100"
  );

  // save labels in localStorage
  localStorage.setItem("labels", JSON.stringify(labels));
};

export const getStatusData = (shipments, status) => {
  const data = filterByStatus(shipments, status);
  const percent = getPercents(shipments.length, data.length);
  return { data, percent };
};

const filterByStatus = (shipments, status) => {
  return shipments.filter((shipment) => shipment.status === status);
};

const getPercents = (total, value) => {
  const percent = (value * 100) / total;
  return percent.toFixed(2);
};

export const getNowShipments = (shipments) => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const monthFormatted = month < 10 ? `0${month}` : month;
  return shipments.filter((shipment) =>
    shipment.created_at.includes(`${year}-${monthFormatted}`)
  );
};

export const sumPackages = (shipments) => {
  try {
    const totalPackages = shipments.reduce(
      (acc, shipment) =>
        acc + shipment.packages ? Number(shipment.packages) : 0,
      0
    );
    return totalPackages;
  } catch (e) {
    console.log(e);
    return 0;
  }
};
