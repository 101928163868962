<template>
  <p v-if="props.filteredShipments.length === 0">No hay envíos para mostrar</p>
  <DataTable
    :value="props.filteredShipments"
    showGridlines
    tableStyle="min-width: 50rem; margin-top:20px"
    :sort-field="sortField"
    :sort-order="sortOrder"
    @sort-change="onSortChange"
    v-else
  >
    <Column>
      <template #header>
        <Checkbox
          style="margin-right: 0.4rem"
          v-model="selectAll"
          @click="selectAllShipments"
          :binary="true"
        />
      </template>
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <Checkbox v-model="isSelected[data.id]" :binary="true" />
        </div>
      </template>
    </Column>
    <Column
      field="delivery_number"
      header="N° Envío"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center clickeable">
          <a @click="props.goToShipmentDetail(data)">
            <u>
              {{ data.delivery_number }}
            </u>
          </a>
        </div>
      </template>
    </Column>
    <Column
      field="status"
      header="Estado"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <a href="/#/shipments">
            <u @click="goToStatus(data)">
              {{ data.status }}
            </u>
          </a>
        </div>
      </template>
    </Column>
    <Column
      field="sold_at"
      header="Fecha Creación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.sold_at) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="commitment_date"
      header="Fecha Compromiso"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.commitment_date) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="sold_at"
      header="Días de Tránsito"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ transitDays(data) }}</span>
        </div>
      </template>
    </Column>
    <Column header="Canal de Venta" field="sale_channel"> </Column>
    <Column header="Destinatario" field="shipping_name"> </Column>
    <Column header="Dirección" field="shipping_address_1"> </Column>
    <Column header="Comuna" field="shipping_city"> </Column>
    <Column header="Valor CLP" field="total"> </Column>
    <Column header="Acciones">
      <template #body="{ data }">
        <div class="action-list">
          <i
            v-if="data.manifest_id"
            class="pi pi-file actions"
            @click="downloadManifest(data)"
            style="color: #326eb5; cursor: pointer; margin-left: 5px"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <div style="text-align: right; padding-top: 1rem">
    <Button
      style="margin-left: 1rem; padding: 0.7rem"
      label="Descargar"
      icon="pi pi-file-excel"
      @click="exportSelectedShipments"
      :disabled="isLoading || !hasSelectedShipments"
    />
  </div>
</template>
<script setup>
import { defineProps, ref, watch, reactive, computed } from "vue";
import { formatDateTime } from "@/services/utils";
import { transitDays } from "../../services/utils";
import Checkbox from "primevue/checkbox";
import { exportToExcel } from "../../services/exportExcel";
import { downloadManifest } from "../../services/manifest";

const sortField = ref(null);
const sortOrder = ref(1);
const props = defineProps({
  filteredShipments: Array,
  showToast: Function,
  loadShipments: Function,
  setActiveTab: Function,
  setSerchedText: Function,
  customer: Object,
  shipments: Array,
  goToShipmentDetail: Function,
});
const selectAll = ref(false);
const isSelected = reactive({});
const isLoading = ref(false);

const goToStatus = (shipment) => {
  props.setActiveTab(shipment.sequence_number, shipment.delivery_number);
};

watch(
  () => props.filteredShipments,
  (newValue) => {
    newValue.forEach((shipment) => {
      isSelected[String(shipment.id)] = false;
    });
  }
);

const selectedShipments = computed(() => {
  return Object.keys(isSelected).filter((id) => isSelected[id]);
});

const hasSelectedShipments = computed(() => {
  return selectedShipments.value.length > 0;
});

const selectAllShipments = () => {
  selectAll.value = !selectAll.value;
  for (const id in isSelected) {
    isSelected[id] = selectAll.value;
  }
};

const exportSelectedShipments = () => {
  const selectedShipmentIds = selectedShipments.value;
  const selectedShipmentsData = props.shipments.filter((shipment) =>
    selectedShipmentIds.includes(String(shipment.id))
  );

  exportToExcel(selectedShipmentsData, props.customer);
};

const onSortChange = (event) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
  font-size: 10px;
}
</style>
