<template>
  <p v-if="props.filteredShipments.length === 0">
    No se encontraron envíos en preparación
  </p>
  <DataTable
    :value="props.filteredShipments"
    showGridlines
    tableStyle="min-width: 50rem; margin-top:20px"
    :sort-field="sortField"
    :sort-order="sortOrder"
    @sort-change="onSortChange"
    v-else
  >
    <Column>
      <template #header>
        <Checkbox
          style="margin-right: 0.4rem"
          v-model="selectAll"
          @click="selectAllShipments"
          :binary="true"
        />
      </template>
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <Checkbox v-model="isSelected[data.id]" :binary="true" />
        </div>
      </template>
    </Column>
    <Column
      field="delivery_number"
      header="N° Envío"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center clickeable">
          <a @click="props.goToShipmentDetail(data)">
            <u>
              {{ data.delivery_number }}
            </u>
          </a>
        </div>
      </template>
    </Column>
    <Column header="Orden de transporte" field="transport_order"> </Column>
    <Column
      field="sold_at"
      header="Fecha Creación"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.sold_at) }}</span>
        </div>
      </template>
    </Column>
    <Column
      field="commitment_date"
      header="Fecha Compromiso"
      :sortable="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
    >
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ formatDateTime(data.commitment_date) }}</span>
        </div>
      </template>
    </Column>
    <Column header="Destinatario" field="shipping_name"> </Column>
    <Column header="Dirección" field="shipping_address_1"> </Column>
    <Column header="Comuna" field="shipping_city"> </Column>
    <Column header="Valor CLP" field="total"> </Column>
    <Column header="Bodega">
      <template #body="{ data }">
        <div class="p-d-flex p-ai-center">
          <span>{{ data.origin_address_alias }}</span>
        </div>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{ data }">
        <div style="display: flex; justify-content: space-around">
          <i
            class="pi pi-pencil actions"
            @click="setCurrentShipment(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-trash actions"
            @click="onDeleteButtonPress(data)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            class="pi pi-comments actions"
            @click="createTicketFromShipment(data.id, data.delivery_number)"
            style="color: #326eb5"
          />
          &nbsp;
          <i
            v-if="data.manifest_id"
            class="pi pi-file actions"
            @click="downloadManifest(data)"
            style="color: #326eb5; cursor: pointer; margin-left: 5px"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <div v-if="props.filteredShipments.length > 0">
    Seleccionados: {{ selectedShipments.length }} envíos
  </div>
  <div style="text-align: right; padding-top: 1rem">
    <Button
      label="Submit"
      style="background-color: #eb7c2a; padding: 0.7rem"
      @click="onPrintLabelsClick"
      :disabled="isLoading || !hasSelectedShipments"
    >
      <i
        class="pi pi-print"
        style="color: white; font-size: 1rem; padding-right: 0.5rem"
      />
      Imprimir etiquetas
      <i
        v-if="isLoading"
        class="pi pi-spin pi-spinner"
        style="font-size: 1rem; margin-left: 0.5rem"
      />
    </Button>
    <Button
      label="Submit"
      style="
        background-color: var(--blue-500);
        padding: 0.7rem;
        margin-left: 10px;
      "
      @click="handleManifest"
      :disabled="isLoading || !hasSelectedShipments"
    >
      <i
        class="pi pi-print"
        style="color: white; font-size: 1rem; padding-right: 0.5rem"
      />
      Crear manifiesto
      <i
        v-if="isLoading"
        class="pi pi-spin pi-spinner"
        style="font-size: 1rem; margin-left: 0.5rem"
      />
    </Button>
    <Button
      style="margin-left: 1rem; padding: 0.7rem"
      label="Descargar"
      icon="pi pi-file-excel"
      @click="exportSelectedShipments"
      :disabled="isLoading || !hasSelectedShipments"
    />
  </div>
</template>
<script setup>
import { defineProps, ref, reactive, watch, defineEmits, computed } from "vue";
import { formatDateTime } from "@/services/utils";
import { handlePrintLabelsClick } from "../../services/generateLabels";
import Checkbox from "primevue/checkbox";
import { deleteManifest } from "@/api/manifest";
import { printLabels, sumPackages } from "../../services/shipments";
import { useConfirm } from "primevue/useconfirm";
import { createTicketFromShipment } from "../../services/support";
import { exportToExcel } from "../../services/exportExcel";
import { createManifest } from "../../api/manifest";
import { getManifestPDF } from "../../services/manifest";
import { uploadFile } from "../../api/files";
import { saveFileName } from "../../api/manifest";
import { downloadManifest } from "../../services/manifest";

const sortField = ref(null);
const sortOrder = ref(1);
const isSelected = reactive({});
const selectAll = ref(false);
const props = defineProps({
  filteredShipments: Array,
  showToast: Function,
  loadShipments: Function,
  removeConfirm: Function,
  customer: Object,
  shipments: Array,
  user: Object,
  setShowEditShipment: Function,
  setCurrentShipment: Function,
  makeSync: Function,
  goToShipmentDetail: Function,
  handlePdfPreviewUrl: Function,
});
const emit = defineEmits(["selected-shipments", "open-preview-modal"]);
const isLoading = ref(false);
const confirm = useConfirm();

const onDeleteButtonPress = async (shipment) => {
  try {
    if (shipment.manifest_id) {
      confirm.require({
        message:
          "El envío está asociado a un manifiesto. ¿Desea eliminar el manifiesto?",
        header: "No se puede eliminar este envío",
        icon: "pi pi-info-alert",
        acceptLabel: "Si",
        rejectLabel: "No",
        accept: async () => {
          await removeManifest(shipment.manifest_id);
          props.removeConfirm(shipment);
        },
      });
      return;
    }
    isLoading.value = true;
    await props.removeConfirm(shipment);
    isLoading.value = false;
  } catch (err) {
    isLoading.value = false;
    props.showToast("error", "No se pudo completar la operación ", err);
  }
};

watch(isSelected, (newVal) => {
  const selectedIds = Object.keys(newVal).filter((id) => newVal[id]);
  const selectedShipments = selectedIds.map((id) => parseInt(id));
  emit("selected-shipments", selectedShipments);
});

watch(
  () => props.filteredShipments,
  (newValue) => {
    for (const id in isSelected) {
      isSelected[id] = false;
    }
    newValue.forEach((shipment) => {
      isSelected[String(shipment.id)] = false;
    });
  }
);

const hasEmptyTransportOrder = (shipments) => {
  return shipments.some((shipment) => shipment.transport_order === "-");
};

const hasPreviousManifest = (shipments) => {
  return shipments.some((shipment) => shipment.manifest_id);
};

const getSelectedShipments = (selectedIds) => {
  return props.filteredShipments.filter((shipment) =>
    selectedIds.includes(String(shipment.id))
  );
};

const getShipmentLabels = () => {
  const selectedIds = Object.keys(isSelected).filter((id) => isSelected[id]);
  const selectedShipments = getSelectedShipments(selectedIds);
  return selectedShipments.map((shipment) => {
    return shipment.label;
  });
};

const exportSelectedShipments = () => {
  const selectedShipmentIds = selectedShipments.value;
  const selectedShipmentsData = props.shipments.filter((shipment) =>
    selectedShipmentIds.includes(String(shipment.id))
  );

  exportToExcel(selectedShipmentsData, props.customer);
};

const onPrintLabelsClick = async () => {
  try {
    isLoading.value = true;
    const selectedIds = Object.keys(isSelected).filter((id) => isSelected[id]);
    const selectedShipments = getSelectedShipments(selectedIds);
    const labels = getShipmentLabels();
    const existsEmptyTransportOrder = hasEmptyTransportOrder(selectedShipments);

    if (existsEmptyTransportOrder) {
      await handlePrintLabelsClick(selectedIds, selectedShipments);
      props.makeSync();
    } else {
      printLabels(labels);
    }
    isLoading.value = false;
  } catch (err) {
    props.showToast("error", "Error al imprimir las etiquetas", err);
    isLoading.value = false;
  }
};

const hasSelectedShipments = computed(() => {
  return selectedShipments.value.length > 0;
});

const handleManifest = async () => {
  try {
    isLoading.value = true;
    const selectedIds = Object.keys(isSelected).filter((id) => isSelected[id]);
    const selectedShipments = getSelectedShipments(selectedIds);
    const existsEmptyTransportOrder = hasEmptyTransportOrder(selectedShipments);
    if (existsEmptyTransportOrder) {
      throw new Error(
        "No se puede imprimir manifiesto, Debes generar la etiqueta primero"
      );
    }
    const existsPreviousManifest = hasPreviousManifest(selectedShipments);
    if (existsPreviousManifest) {
      throw new Error(
        "Hay envíos seleccionados que ya cuentan con un manifiesto"
      );
    }

    const totalPackages = sumPackages(selectedShipments);

    const payload = {
      shipmentIds: selectedShipments.map((shipment) => shipment.id),
      userId: props.user.id,
      customerId: props.customer.id,
      courierId: 1,
      status: "creado",
      packages: totalPackages,
    };

    const manifestData = await createManifest(payload);

    const pdf = await getManifestPDF(selectedShipments, manifestData.data.id);

    const fileName = `${manifestData.data.id}.pdf`;

    await uploadFile(pdf.output("blob"), fileName, "manifests");

    await saveFileName(manifestData.data.id, fileName);

    const objectUrl = URL.createObjectURL(
      new Blob([pdf.output("blob")], { type: "application/pdf" })
    );

    props.handlePdfPreviewUrl(objectUrl);

    isLoading.value = false;
    props.showToast("success", "Manifiesto creado con éxito");
    props.makeSync();
  } catch (err) {
    isLoading.value = false;
    props.showToast("error", "Error al crear el manifiesto", err);
  }
};

const selectAllShipments = () => {
  selectAll.value = !selectAll.value;
  for (const shipment of props.filteredShipments) {
    isSelected[String(shipment.id)] = selectAll.value;
  }
};

const selectedShipments = computed(() => {
  return Object.keys(isSelected).filter((id) => isSelected[id]);
});

const removeManifest = async (manifestId) => {
  try {
    await deleteManifest(manifestId);
    props.showToast("success", "Manifiesto eliminado con éxito");
  } catch (err) {
    props.showToast("error", "Error al eliminar el manifiesto", err);
  }
};

const setCurrentShipment = (shipment) => {
  props.setCurrentShipment(shipment);
  if (shipment.manifest_id) {
    confirm.require({
      message:
        "El envío está asociado a un manifiesto, ¿desea eliminar el manifiesto?",
      header: "No se puede editar este envío",
      icon: "pi pi-info-alert",
      acceptLabel: "Si",
      rejectLabel: "No",
      accept: async () => {
        await removeManifest(shipment.manifest_id);
        props.setShowEditShipment(true);
      },
    });
    return;
  }
  props.setShowEditShipment(true);
};

const onSortChange = (event) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};
</script>
