import { newShipment } from "../api/courier";
import { updateStatus } from "../api/shipment";
import {
  buildChilexpressPayload,
  getCoverageArea,
  onShippingFailed,
} from "./couriers";
import { printLabels } from "./shipments";
import { getAttributes } from "../api/courier";

export async function loadCourierAttributes(courierId) {
  const response = await getAttributes(courierId);
  return response.data;
}

export function findAttribute(attributes, name) {
  const attribute = attributes.find((attr) => attr.attribute === name);
  return attribute ? attribute.value : "";
}

export async function handleShippingSuccess(
  id,
  label,
  transportOrder,
  barcode
) {
  try {
    await updateStatus(id, {
      statusId: "2",
      label: label,
      transportOrder: transportOrder,
      courierId: 1,
      barcode: barcode,
    });
  } catch (error) {
    console.error("Error al actualizar el estado del envío", error);
  }
}

export async function handlePrintLabelsClick(selectedIds, shipments) {
  const courierAttributes = await loadCourierAttributes(1);
  const cardNumber = findAttribute(courierAttributes, "card_number");
  const marketplaceRut = findAttribute(courierAttributes, "marketplace_rut");
  const labels = [];

  for (const selectedId of selectedIds) {
    const shipmentInfo = shipments.find(
      (shipment) => shipment.id === selectedId
    );
    let originCoverageArea = null;
    let destinationCoverageArea = null;

    try {
      originCoverageArea = await getCoverageArea(
        shipmentInfo.origin_region_code,
        shipmentInfo.origin_address_city
      );
    } catch (err) {
      alert(err);
      onShippingFailed(shipmentInfo.id, 1);
      continue;
    }

    try {
      destinationCoverageArea = await getCoverageArea(
        shipmentInfo.region_code,
        shipmentInfo.shipping_city
      );
    } catch (err) {
      alert(err);
      onShippingFailed(shipmentInfo.id, 1);
      continue;
    }

    const payload = buildChilexpressPayload(
      shipmentInfo,
      cardNumber,
      marketplaceRut,
      originCoverageArea,
      destinationCoverageArea
    );

    const result = await newShipment(1, payload);
    const label = result.data.detail[0].label.labelData;
    labels.push(label);
    handleShippingSuccess(
      selectedId,
      label,
      result.data.detail[0].transportOrderNumber,
      result.data.detail[0].barcode
    );
  }

  printLabels(labels);
}
